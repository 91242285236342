<template>
  <!-- Service Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div
        class="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 600px"
      >
        <h6 class="text-primary">Our Services</h6>
        <h1 class="mb-4">Experts in Cloud Technology and DevOps</h1>
      </div>
      <div class="row g-4">
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/cloudSolutions600x400.png" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-solar-panel fa-3x"></i>
              </div>
              <h4 class="mb-3">Cloud Solutions</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/agile.png" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-wind fa-3x"></i>
              </div>
              <h4 class="mb-3">Agile Practices</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/devops600x400.jpg" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-lightbulb fa-3x"></i>
              </div>
              <h4 class="mb-3">DevOps CI/CD Adoption</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/eai-600x400.jpg" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-solar-panel fa-3x"></i>
              </div>
              <h4 class="mb-3">Application Integration</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/bi.png" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-wind fa-3x"></i>
              </div>
              <h4 class="mb-3">Business Intelligence</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item rounded overflow-hidden">
            <img class="img-fluid" src="img/img-600x400-6.png" alt="" />
            <div class="position-relative p-4 pt-0">
              <div class="service-icon">
                <i class="fa fa-lightbulb fa-3x"></i>
              </div>
              <h4 class="mb-3">Application Development</h4>
              <!-- <p>
                Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem
                diam.
              </p> -->
              <a class="small fw-medium" href="">
                Read More
                <i class="fa fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service End -->
</template>

<script>
export default {
  name: "Services",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
