<template>
  <div class="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
    <div class="owl-carousel header-carousel position-relative" ref="carousel">
      <div
        class="owl-carousel-item position-relative"
        data-dot="<img src='../../img/carousel-1.png'>"
      >
        <img
          class="img-fluid img-fluid-roll"
          src="../../public/img/carousel-1.png"
          alt=""
        />
        <div class="owl-carousel-inner">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-10 col-lg-8">
                <h1 class="display-2 text-white animated slideInDown">
                  Deliver superior experience
                </h1>
                <p class="fs-5 fw-medium text-white mb-4 pb-3">
                  Our customer leverage modern delivery platform to enhance user
                  experience.
                </p>
                <a
                  href=""
                  class="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="owl-carousel-item position-relative"
        data-dot="<img src='../../img/carousel-2.png'>"
      >
        <img
          class="img-fluid img-fluid-roll"
          src="../../public/img/carousel-2.png"
          alt=""
        />
        <div class="owl-carousel-inner">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-10 col-lg-8">
                <h1 class="display-2 text-white animated slideInDown">
                  DevOps Maturity Modal
                </h1>
                <p class="fs-5 fw-medium text-white mb-4 pb-3">
                  We have helped teams to optimize there DevOps capability.
                </p>
                <a
                  href=""
                  class="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="owl-carousel-item position-relative"
        data-dot="<img src='../../img/carousel-3.png'>"
      >
        <img
          class="img-fluid img-fluid-roll"
          src="../../public/img/carousel-3.png"
          alt=""
        />
        <div class="owl-carousel-inner">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-10 col-lg-8">
                <h1 class="display-2 text-white animated slideInDown">
                  Business Intelligence
                </h1>
                <p class="fs-5 fw-medium text-white mb-4 pb-3">
                  Extract information from your data that leads to action.
                </p>
                <a
                  href=""
                  class="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="owl-carousel-item position-relative"
        data-dot="<img src='../../img/carousel-4.jpg'>"
      >
        <img
          class="img-fluid img-fluid-roll"
          src="../../public/img/carousel-4.jpg"
          alt=""
        />
        <div class="owl-carousel-inner">
          <div class="container">
            <div class="row justify-content-start">
              <div class="col-10 col-lg-8">
                <h1 class="display-2 text-white animated slideInDown">
                  Integrating data from applications
                </h1>
                <p class="fs-5 fw-medium text-white mb-4 pb-3">
                  Connect Legacy application data to modern apps and innovate
                </p>
                <a
                  href=""
                  class="btn btn-primary rounded-pill py-3 px-5 animated slideInLeft"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
export default {
  name: "Banner",
  props: {},
  setup() {
    onMounted(() => {
      console.log("Banner mounted");
      const $ = window.$; // you need this for the below functions to be called
      // Header carousel
      $(".header-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1500,
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        dotsData: true,
      });
      // end of header carousel

      // Testimonial Start
      // Testimonials carousel
      $(".testimonial-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1000,
        center: true,
        dots: false,
        loop: true,
        nav: true,
        navText: [
          '<i class="bi bi-arrow-left"></i>',
          '<i class="bi bi-arrow-right"></i>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
        },
      });
      // Testimonial End
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
