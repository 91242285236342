<template>
  <!-- Footer Start -->
  <div
    class="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Contact</h5>
          <!--<p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Ashburn Virginia</p> -->
          <p class="mb-2">
            <i class="fa fa-phone-alt me-3"></i>
            +1 571-294-3302
          </p>
          <p class="mb-2 email">
            <i class="fa fa-envelope me-3"></i>
            <a href="mailto:info@excelray.com">info@excelray.com</a>
          </p>
          <div class="d-flex pt-2">
            <a class="btn btn-square btn-outline-light btn-social" href="">
              <i class="fab fa-twitter"></i>
            </a>
            <a class="btn btn-square btn-outline-light btn-social" href="">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a class="btn btn-square btn-outline-light btn-social" href="">
              <i class="fab fa-youtube"></i>
            </a>
            <a class="btn btn-square btn-outline-light btn-social" href="">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h5 class="text-white mb-4">Quick Links</h5>
          <router-link to="/" class="btn btn-link">Home</router-link>
          <router-link to="/about" class="btn btn-link">About</router-link>
          <router-link to="/contactus" class="btn btn-link">
            Contact
          </router-link>
          <router-link to="/services" class="btn btn-link">
            Our Services
          </router-link>
          <router-link to="/" class="btn btn-link">
            Terms & Condition
          </router-link>
        </div>
        <!--Project Galary start-->
        <!--  <div class="col-lg-3 col-md-6">
                    <h5 class="text-white mb-4">Project Gallery</h5>
                    <div class="row g-2">
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-1.jpg" alt="">
                        </div>
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-2.jpg" alt="">
                        </div>
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-3.jpg" alt="">
                        </div>
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-4.jpg" alt="">
                        </div>
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-5.jpg" alt="">
                        </div>
                        <div class="col-4">
                            <img class="img-fluid rounded" src="img/gallery-6.jpg" alt="">
                        </div>
                    </div>
                </div> -->
        <!--Project Galary end-->
        <!-- <div class="col-lg-3 col-md-6">
                    <h5 class="text-white mb-4">Newsletter</h5>
                    <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                    <div class="position-relative mx-auto" style="max-width: 400px;">
                        <input class="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email">
                        <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div>
                </div>-->
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
            &copy;
            <a href="#">Excelray LLC</a>
            , All Right Reserved.
          </div>

          <div
            class="col-md-6 text-center text-md-end"
            style="font-size: xx-small; color: #003b62"
          >
            Designed By
            <a href="https://htmlcodex.com " style="color: #003b62">
              HTML Codex
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
