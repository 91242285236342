import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Notifications from "@kyvg/vue3-notification";

createApp(App)
  .use(store)
  .use(router)
  .use(VueReCaptcha, { siteKey: "6LePHzEiAAAAAFYKKTE5MSUqlFuoILbSz1bWEZBw" })
  .use(Notifications)
  .mount("#app");
