<template>
  <!-- Navbar Start -->

  <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
    <router-link
      to="/"
      class="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
    >
      <img class="img-fluid" src="img/excelraylogo.png" alt="excelray logo" />
    </router-link>

    <button
      type="button"
      class="navbar-toggler me-4"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto p-4 p-lg-0">
        <!-- <a href="index.html" class="nav-item nav-link active">Home</a>
        <a href="about.html" class="nav-item nav-link">About US</a>
        <a href="service.html" class="nav-item nav-link">Service</a>
        <a href="contact.html" class="nav-item nav-link">Contact</a> -->
        <router-link to="/" class="nav-item nav-link">Home</router-link>
        <router-link to="/About" class="nav-item nav-link">About</router-link>
        <router-link to="/Services" class="nav-item nav-link">
          Services
        </router-link>
        <router-link to="/ContactUs" class="nav-item nav-link">
          Contact
        </router-link>
        <!-- <a href="project.html" class="nav-item nav-link">Project</a>-->
        <!-- <div class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                    <div class="dropdown-menu bg-light m-0">
                        <a href="feature.html" class="dropdown-item">Feature</a>
                        <a href="quote.html" class="dropdown-item">Free Quote</a>
                        <a href="team.html" class="dropdown-item">Our Team</a>
                        <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                        <a href="404.html" class="dropdown-item">404 Page</a>
                    </div>
                </div> -->
      </div>
      <!-- <a href="" class="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block">Get A Quote<i class="fa fa-arrow-right ms-3"></i></a> -->
    </div>
  </nav>
  <router-view />
  <!-- Navbar End -->
</template>

<script>
export default {
  name: "Navigation",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
