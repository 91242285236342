<template>
  <Banner />
  <Featured />
  <Services />
  <WhyChooseus />
</template>

<script>
import Banner from "@/components/Banner.vue";
import Featured from "@/components/Featured.vue";
import Services from "@/components/Services.vue";
import WhyChooseus from "@/components/WhyChooseus.vue";

// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    Banner,
    Featured,
    Services,
    WhyChooseus,
  },
};
</script>
