<template>
  <!-- Feature Start -->
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-5">
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
          <div class="d-flex align-items-center mb-4">
            <div class="btn-lg-square bg-primary rounded-circle me-3">
              <i class="fa fa-users text-white"></i>
            </div>
            <h1 class="mb-0" data-toggle="counter-up">17</h1>
          </div>
          <h5 class="mb-3">Technology</h5>
          <!-- <span
            >Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span
          > -->
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
          <div class="d-flex align-items-center mb-4">
            <div class="btn-lg-square bg-primary rounded-circle me-3">
              <i class="fa fa-check text-white"></i>
            </div>
            <h1 class="mb-0" data-toggle="counter-up">36</h1>
          </div>
          <h5 class="mb-3">Services</h5>
          <!-- <span
            >Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span
          > -->
        </div>
        <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
          <div class="d-flex align-items-center mb-4">
            <div class="btn-lg-square bg-primary rounded-circle me-3">
              <i class="fa fa-award text-white"></i>
            </div>
            <h1 class="mb-0" data-toggle="counter-up">25</h1>
          </div>
          <h5 class="mb-3">Projects</h5>
          <!-- <span
            >Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span
          > -->
        </div>
        <!--<div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <div class="d-flex align-items-center mb-4">
                        <div class="btn-lg-square bg-primary rounded-circle me-3">
                            <i class="fa fa-users-cog text-white"></i>
                        </div>
                        <h1 class="mb-0" data-toggle="counter-up">23</h1>
                    </div>
                    <h5 class="mb-3">Expert Workers</h5>
                    <span>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit</span>
                </div> -->
      </div>
    </div>
  </div>
  <!-- Feature Start -->
</template>

<script>
import { onMounted } from "vue";
export default {
  name: "Featured",
  props: {},
  setup() {
    onMounted(() => {
      console.log("Featured mounted");
      const $ = window.$; // you need this for the below functions to be called
      // Facts counter
      $('[data-toggle="counter-up"]').counterUp({
        delay: 10,
        time: 2000,
      });
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
