<template>
  <Header />
  <Navigation />
  <notifications />
  <Footer />
</template>
<script>
// @ is an alias to /src location
import Header from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Navigation,
    Footer,
  },
};
</script>
<style></style>
