<template>
  <!-- why choose us Start -->
  <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div class="container feature px-lg-0">
      <div class="row g-0 mx-lg-0">
        <div
          class="col-lg-6 feature-text py-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div class="p-lg-5 ps-lg-0">
            <h6 class="text-primary">Why Choose Us!</h6>
            <h1 class="mb-4">Private Sector & Public Sector</h1>
            <p class="mb-4 pb-2">
              Our Team members have more than a decade of experience in both
              private sector serving fortune 500 companies and public sector
              delivering cutting edge solution to meet customer business goals
            </p>
            <div class="row g-4">
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <div class="btn-lg-square bg-primary rounded-circle">
                    <i class="fa fa-check text-white"></i>
                  </div>
                  <div class="ms-4">
                    <p class="mb-0">Quality</p>
                    <h5 class="mb-0">Services</h5>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <div class="btn-lg-square bg-primary rounded-circle">
                    <i class="fa fa-user-check text-white"></i>
                  </div>
                  <div class="ms-4">
                    <p class="mb-0">Expert</p>
                    <h5 class="mb-0">Team</h5>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <div class="btn-lg-square bg-primary rounded-circle">
                    <i class="fa fa-drafting-compass text-white"></i>
                  </div>
                  <div class="ms-4">
                    <p class="mb-0">Free</p>
                    <h5 class="mb-0">Consultation</h5>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center">
                  <div class="btn-lg-square bg-primary rounded-circle">
                    <i class="fa fa-headphones text-white"></i>
                  </div>
                  <div class="ms-4">
                    <p class="mb-0">Customer</p>
                    <h5 class="mb-0">Support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 pe-lg-0 wow fadeIn"
          data-wow-delay="0.5s"
          style="min-height: 400px"
        >
          <div class="position-relative h-100">
            <img
              class="position-absolute img-fluid w-100 h-100"
              src="img/feature.png"
              style="object-fit: cover"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- why choose us End -->
</template>

<script>
export default {
  name: "WhyChooseus",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
