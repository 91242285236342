<template>
  <!-- Topbar Start -->
  <div class="container-fluid bg-dark p-0">
    <div class="row gx-0 d-none d-lg-flex">
      <div class="col-lg-7 px-5 text-start"></div>
      <div class="col-lg-5 px-5 text-end">
        <div class="h-100 d-inline-flex align-items-center me-4">
          <small class="fa fa-phone-alt text-light me-2"></small>
          <small class="text-light">+1 571-294-3302</small>
        </div>
        <div class="h-100 d-inline-flex align-items-center mx-n2">
          <a
            class="btn btn-square text-light rounded-0 border-0 border-end border-secondary"
            href=""
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            class="btn btn-square text-light rounded-0 border-0 border-end border-secondary"
            href=""
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            class="btn btn-square text-light rounded-0 border-0 border-end border-secondary"
            href=""
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <!-- <a class="btn btn-square btn-link rounded-0" href=""><i class="fab fa-instagram"></i></a>-->
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->
</template>

<script>
export default {
  name: "Header",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
